import { useEffect } from "react";
import { Checkbox, Form } from "semantic-ui-react";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import useTitle from "../hooks/useTitle";
import { strings } from "../utils/i18n.utils";

export const AudioSettings = () => {
    const { currentSpace, languageSettings, setLanguageSettings } = useAuth();

    useTitle(strings.settings_title, strings.settings);
    const [languageSettingsAPI, callLanguageSettingsAPI] = useAPI();

    function updateLanguageSettings(languageSettings) {
        setLanguageSettings(languageSettings);
        callLanguageSettingsAPI("PUT", `/api/spaces/${currentSpace.id}/settings`, languageSettings);
    }

    useEffect(() => {
        if (languageSettingsAPI.response) {
            setLanguageSettings(languageSettingsAPI.response);
        }
    }, [languageSettingsAPI.response]);

    return (
        <Form>
            <Form.Field>
                <Checkbox
                    toggle
                    label={strings.setting_audio_enabled}
                    checked={languageSettings.audio_enabled}
                    onChange={(e, { checked }) =>
                        updateLanguageSettings({ ...languageSettings, audio_enabled: checked })
                    }></Checkbox>
            </Form.Field>
            <Form.Field>
                <Checkbox
                    toggle
                    label={strings.setting_hands_free_mode_enabled}
                    checked={languageSettings.hands_free_mode_enabled}
                    onChange={(e, { checked }) =>
                        updateLanguageSettings({ ...languageSettings, hands_free_mode_enabled: checked })
                    }></Checkbox>
            </Form.Field>
            <Form.Field>
                <Checkbox
                    toggle
                    label={strings.setting_listening_disabled_until}
                    checked={languageSettings.listening_disabled_until !== null}
                    onChange={(e, { checked }) =>
                        updateLanguageSettings({
                            ...languageSettings,
                            cant_listen_now: checked,
                            listening_disabled_until: checked ? new Date() : null,
                        })
                    }></Checkbox>
            </Form.Field>
            <Form.Field>
                <Checkbox
                    toggle
                    label={strings.setting_speaking_disabled_until}
                    checked={languageSettings.speaking_disabled_until !== null}
                    onChange={(e, { checked }) =>
                        updateLanguageSettings({
                            ...languageSettings,
                            cant_speak_now: checked,
                            speaking_disabled_until: checked ? new Date() : null,
                        })
                    }></Checkbox>
            </Form.Field>
        </Form>
    );
};
