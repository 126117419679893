import React, { useEffect } from "react";
import { ZataLoader } from "../components/ZataLoader";
import { COLOR_CORRECT, COLOR_INCORRECT, ProgressChart } from "../components/Progress";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import { strings } from "../utils/i18n.utils";

const ScoresScreen = () => {
    const [scoresAPI, callScoresAPI] = useAPI({ loading: true });
    const { statistics, currentSpace } = useAuth();

    useEffect(() => {
        callScoresAPI("GET", `/api/spaces/${currentSpace.id}/scores`);
    }, []);

    if (scoresAPI.response === null || scoresAPI.loading) {
        return <ZataLoader />;
    }

    const datasets = [
        [strings.skill_listening, scoresAPI.response.listening_scores, scoresAPI.response.estimated_listening_ability],
        [strings.skill_reading, scoresAPI.response.reading_scores, scoresAPI.response.estimated_reading_ability],
        [strings.skill_speaking, scoresAPI.response.speaking_scores, scoresAPI.response.estimated_speaking_ability],
        [strings.skill_writing, scoresAPI.response.writing_scores, scoresAPI.response.estimated_writing_ability],
    ];

    return (
        <>
            {datasets.map(([name, dataset, ability], idx) => (
                <ProgressChart
                    dataLabel={name}
                    chartType="scatter"
                    labels={dataset.map((p, idx) => idx)}
                    datasets={[
                        {
                            label: "Correct",
                            data: dataset.map((p) => (p.correct === true ? p.difficulty : null)),
                            backgroundColor: COLOR_CORRECT,
                            statistic: ability?.toFixed(1),
                            statisticTitle: `${name} Ability`,
                        },
                        {
                            label: "Incorrect",
                            data: dataset.map((p) => (p.correct === false ? p.difficulty : null)),
                            backgroundColor: COLOR_INCORRECT,
                            showStatistic: false,
                        },
                    ]}
                />
            ))}
        </>
    );
};
export default ScoresScreen;
