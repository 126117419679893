import React, { useEffect, useState } from "react";
import { AudioPlayButton } from "./AudioPlayer";

export default function AudioPhrase({ phrase, autoPlayAudio, setPlayingAudio, additionalActions, voiceId }) {
    const [audioUrl, setAudioUrl] = useState(null);

    useEffect(() => {
        let url = process.env.REACT_APP_SERVER_URL + `/api/phrases/${phrase.id}/audio?`;

        if (voiceId) {
            url += `voice_id=${voiceId}&`;
        }
        setAudioUrl(url);
    }, [phrase]);

    return (
        <div>
            <div className="button-row">
                {audioUrl && (
                    <>
                        <AudioPlayButton
                            url={audioUrl}
                            size={"small"}
                            autoPlay={autoPlayAudio}
                            setPlayingAudio={setPlayingAudio}
                            buttonStyle="button"
                            includeText={true}
                        />
                        <AudioPlayButton
                            url={audioUrl + "slow=true"}
                            size={"small"}
                            setPlayingAudio={setPlayingAudio}
                            buttonStyle="button"
                            includeText={true}
                            slowPlay={true}
                        />
                    </>
                )}
            </div>
        </div>
    );
}
