import { useEffect, useState } from "react";
import Skill from "../components/Skill";
import { ZataLoader } from "../components/ZataLoader";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import { strings } from "../utils/i18n.utils";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button, Divider, Input, Tab } from "semantic-ui-react";
import { Row } from "../components/Flex";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import { currentLocation } from "../utils/url.utils";
import { Plus } from "@phosphor-icons/react";
import { HomeTopBarPortal } from "../components/HomeTopBar";
import { useQuery } from "../hooks/useQuery";

const SkillsScreen = () => {
    const { currentSpace } = useAuth();
    const [skillsAPI, callSkillsAPI] = useAPI();
    const [skills, setSkills] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [searchTextCommitted, setSearchTextCommitted] = useState("");
    const [category, setCategory] = useState("all");
    const history = useHistory();

    const limit = 20;
    const query = useQuery();

    useEffect(() => {
        if (query.get("category")) {
            setCategory(query.get("category"));
        } else {
            setCategory("all");
        }
    }, [query.get("category")]);

    function removeSkill(skill) {
        skillsAPI.setResponse(skillsAPI.response.filter((s) => s.id !== skill.id));
    }

    useEffect(() => {
        if (searchText === "") {
            setSearchTextCommitted("");
        } else {
            const delayDebounceFn = setTimeout(() => {
                setSearchTextCommitted(searchText);
            }, 500);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [searchText]);

    useEffect(() => {
        refresh();
    }, [category, searchTextCommitted]);

    function refresh() {
        setSkills([]);
        loadMore(true);
    }

    console.log("Category", category);

    function loadMore(refresh = false) {
        let url = `/api/spaces/${currentSpace.id}/skills?offset=${
            refresh ? 0 : skills.length
        }&limit=${limit}&category=${category}`;
        if (searchText) {
            url += `&searchText=${searchText}`;
        }
        callSkillsAPI("GET", url);
    }

    useEffect(() => {
        if (skillsAPI.response) {
            setSkills([...skills, ...skillsAPI.response]);
            setHasMore(skillsAPI.response.length === limit);
        }
    }, [skillsAPI.response]);

    const categories = ["", "new", "in_review", "mastered"];

    const getCategoryIndex = (category) => {
        return categories.includes(category) ? categories.indexOf(category) : 0;
    };

    const handleTabChange = (e, { activeIndex }) => {
        skillsAPI.setLoading(true);
        history.push(`/spaces/${currentSpace.id}/skills?category=${categories[activeIndex]}`);
    };

    const panes = [
        {
            menuItem: "All",
            to: `/spaces/${currentSpace.id}/skills`,
            secondary: true,
        },
        {
            menuItem: "Up Next",
            to: `/spaces/${currentSpace.id}/skills?category=new`,
            secondary: true,
        },
        {
            menuItem: "In Review",
            to: `/spaces/${currentSpace.id}/skills?category=in_review`,
            secondary: true,
        },
        {
            menuItem: "Completed",
            to: `/spaces/${currentSpace.id}/skills?category=mastered`,
            secondary: true,
        },
    ];

    return (
        <>
            <HomeTopBarPortal />

            <Row align="right">
                <div></div>
                <Button
                    as={Link}
                    to={`/spaces/${currentSpace.id}/topics/new?back=${currentLocation()}`}
                    icon={<Plus />}
                    content={strings.add}
                />
            </Row>

            <Tab
                panes={panes}
                onTabChange={handleTabChange}
                activeIndex={getCategoryIndex(category)}
                menu={{ secondary: true, pointing: true }}
                style={{
                    marginBottom: "1rem",
                }}
            />
            <Input
                fluid
                icon="search"
                placeholder={strings.search}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
            />

            {!skillsAPI.loading && skills.length === 0 && <p>{strings.skills_empty_state}</p>}

            <InfiniteScroll
                dataLength={skills.length}
                next={loadMore}
                hasMore={hasMore}
                loader={<ZataLoader />}
                refreshFunction={refresh}
                pullDownToRefresh
                pullDownToRefreshThreshold={50}
                pullDownToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>}
                releaseToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>}
                scrollableTarget="content">
                {skills.map((skill) => (
                    <Skill skill={skill} removeSkill={() => removeSkill(skill)} />
                ))}
            </InfiniteScroll>
        </>
    );
};

export default SkillsScreen;
