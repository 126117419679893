const DailyProgressBar = ({ points, pointsGoal }) => {
    const percentage = points ? Math.min(100, Math.round((points / pointsGoal) * 100)) : 0;

    return (
        <div
            className="ui small progress"
            style={{
                margin: "0 0 0.25rem 0",
                display: "inline-block",
                backgroundColor: "var(--primary-color-inverted)",
                color: "var(--primary-color-inverted)",
                border: "2px solid var(--primary-background-color-inverted)",
                width: "10em",
                verticalAlign: "middle",
            }}>
            <div
                className="bar"
                style={{
                    height: "1.2rem",
                    width: `${percentage}%`,
                    fontWeight: "400",
                }}>
                <div className="progress"></div>
            </div>
        </div>
    );
};

export default DailyProgressBar;
