import { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { Divider, Tab } from "semantic-ui-react";
import InfiniteScroll from "react-infinite-scroll-component";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import { HomeTopBarPortal } from "./HomeTopBar";
import { ZataLoader } from "./ZataLoader";
import { ActivitySegment } from "./ActivityDetails";
import { useQuery } from "../hooks/useQuery";
import { use } from "react";
import { Divide } from "@phosphor-icons/react";

export const Talk = () => {
    const { currentSpace } = useAuth();
    const [category, setCategory] = useState("next");
    const query = useQuery();
    const history = useHistory();
    const [activitiesAPI, callActivitiesAPI] = useAPI();
    const limit = 20;

    useEffect(() => {
        if (query.get("category")) {
            setCategory(query.get("category"));
        } else {
            setCategory("next");
        }
    }, [query.get("category")]);

    useEffect(() => {
        refresh();
    }, [currentSpace, category]);

    const refresh = () => {
        callActivitiesAPI("GET", `/api/spaces/${currentSpace.id}/activities?limit=${limit}&category=${category}`);
    };

    const loadMore = () => {
        callActivitiesAPI(
            "GET",
            `/api/spaces/${currentSpace.id}/activities?offset=${activitiesAPI.response.length}&limit=${limit}&category=${category}`
        );
    };

    const renderTab = () => {
        return (
            <Tab.Pane>
                <ActivityList />
            </Tab.Pane>
        );
    };

    const panes = [
        {
            menuItem: "Next",
            render: renderTab,
            to: `/spaces/${currentSpace.id}/activities`,
            secondary: true,
        },
        {
            menuItem: "Completed",
            render: renderTab,
            to: `/spaces/${currentSpace.id}/activities?category=completed`,
            secondary: true,
        },
        {
            menuItem: "Archived",
            render: renderTab,
            to: `/spaces/${currentSpace.id}/activities?category=archived`,
            secondary: true,
        },
    ];

    const categories = ["", "completed", "archived"];

    const getCategoryIndex = (category) => {
        return categories.includes(category) ? categories.indexOf(category) : 0;
    };

    const handleTabChange = (e, { activeIndex }) => {
        activitiesAPI.setLoading(true);
        history.push(`/spaces/${currentSpace.id}/activities?category=${categories[activeIndex]}`);
    };

    const ActivityList = () => (
        <>
            {activitiesAPI.response === null && <ZataLoader />}
            {activitiesAPI.response !== null && (
                <InfiniteScroll
                    dataLength={activitiesAPI.response?.length}
                    next={loadMore}
                    hasMore={
                        activitiesAPI.loading || (activitiesAPI.response && activitiesAPI.response.length === limit)
                    }
                    loader={<ZataLoader />}
                    endMessage={<p></p>}
                    refreshFunction={refresh}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}
                    pullDownToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>}
                    releaseToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>}
                    scrollableTarget="content">
                    {activitiesAPI.response.map((activity) => (
                        <ActivitySegment key={activity.id} activity={activity} />
                    ))}
                    {/* Without these the hover menu makes a scroll bar appear */}
                    <Divider />
                    <Divider />
                </InfiniteScroll>
            )}
        </>
    );

    return (
        <>
            <HomeTopBarPortal />
            <Tab
                panes={panes}
                onTabChange={handleTabChange}
                activeIndex={getCategoryIndex(category)}
                menu={{ secondary: true, pointing: true }}
            />
        </>
    );
};
