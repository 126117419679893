import { Button } from "semantic-ui-react";
import useTitle from "../hooks/useTitle";
import { strings } from "../utils/i18n.utils";
import { useLanguage } from "../hooks/useLanguage";

const LanguagesSupported = () => {
    const languageKeys = ["en", "es-mx", "es-es", "fr", "de", "it", "pt-br", "pt-pt"];
    const { nativeLanguage } = useLanguage();
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                columnGap: "2rem",
                rowGap: "1rem",
                fontSize: "0.81rem",
                margin: "0.5rem 0.5rem 2rem 0.5rem",
            }}>
            {languageKeys.map((language, index) => (
                <div style={{}}>
                    <a href={`/topics/${nativeLanguage}/${language}`} key={index}>
                        <img
                            src={`./flags/small/${language}.png`}
                            style={{
                                position: "relative",
                                bottom: "-0.16rem",
                                maxHeight: "0.81rem",
                                marginRight: "0.5rem",
                                borderRadius: "10%",
                            }}
                        />
                        <span style={{ textAlign: "center" }}>{strings[language + "_short_country"]}</span>
                    </a>
                </div>
            ))}
        </div>
    );
};
function Landing() {
    useTitle("Jetway - Intelligent spaced repeition", null, "");

    return (
        <>
            <div className="landing-content" id="landing-content">
                <div className="vertically-centered">
                    <div className={"split-screen"}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}>
                            <div
                                style={{
                                    padding: "2rem",
                                    borderRadius: "0.823rem",
                                    // left align text
                                    textAlign: "left",
                                }}>
                                <p
                                    style={{
                                        maxWidth: "450px",
                                        fontSize: "1.8rem",
                                        marginBottom: "0.5rem",
                                        fontWeight: "800",
                                    }}>
                                    Study <u>skills</u>, not vocabulary.
                                </p>
                                <p style={{ maxWidth: "450px", fontSize: "1.25rem", marginBottom: "1.8rem" }}>
                                    Intelligent spaced repetition that goes beyond flashcards, with interactive
                                    tutoring, exercises, and chats.
                                </p>
                                <Button
                                    content={strings.get_started}
                                    as={"a"}
                                    href={`/login`}
                                    primary
                                    active
                                    size={"large"}
                                />
                            </div>
                        </div>
                        <div className="screenshot-holder">
                            <img src="./product/home.png" className="screenshot" />
                        </div>
                    </div>
                </div>
                <LanguagesSupported />
                {/*
                <p>
                    <a href="/overview">{strings.learn_more}</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                    <a href="/teachers">For Teachers</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                    <a
                        href="https://medium.com/jetway/jetway-build-fluency-fast-for-any-destination-b24f2f8cebce"
                        target="_blank"
                        rel="noreferrer">
                        What's new
                    </a>
                </p>
                */}
            </div>
        </>
    );
}

export default Landing;
