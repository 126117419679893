import { Container, Divider } from "semantic-ui-react";

const Footer = () => {
    return (
        <div className="footer">
            <Container>
                <div>© Jetway Languages, Inc. 2024</div>
                <Divider />
                <div style={{ display: "flex", columnGap: "5rem" }}>
                    <div className="product-links">
                        <a href="/">Home</a>
                        <a href="/overview">Why Jetway</a>
                        <a href="/about">About</a>
                        <a href="/contact">Contact</a>
                        <a href="https://discord.gg/A3nSQEQZ6f">Discord</a>
                    </div>
                    <div className="product-links">
                        <a href="https://app.termly.io/document/terms-and-conditions/5ea102fa-ac9f-4241-b691-47919813eb84">
                            Terms of Service
                        </a>
                        <a href="https://app.termly.io/document/privacy-policy/ffd0261c-f9f8-43fd-aedd-aba61d1cdc11">
                            Privacy Agreement
                        </a>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Footer;
