import { useContext } from "react";
import { AuthProviderContext } from "../providers/AuthProvider";

function useAuth() {
    const {
        user,
        userLoading,
        userError,
        setUser,
        setStatistics,
        setLanguageSettings,
        cookie,
        setCookie,
        currentSpace,
        setCurrentSpace,
        switchSpace,
    } = useContext(AuthProviderContext);

    return {
        user,
        userLoading,
        userError,
        setUser,
        setStatistics,
        setLanguageSettings,
        cookie,
        setCookie,
        currentSpace,
        setCurrentSpace,
        statistics: currentSpace?.statistics,
        languageSettings: currentSpace?.settings,
        switchSpace,
    };
}

export default useAuth;
