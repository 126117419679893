import React, { useEffect, useState } from "react";
import { StatisticsBar, StatisticsRow, StreakIcon } from "../components/StatisticsBar";

import {
    AirplaneTakeoff,
    Alarm,
    Barbell,
    CalendarBlank,
    Ear,
    Flame,
    Notebook,
    Pen,
    SpeakerSimpleNone,
    Trophy,
    UserSound,
} from "@phosphor-icons/react";
import { useLanguage } from "../hooks/useLanguage";
import { strings } from "../utils/i18n.utils";
import DailyProgressBar from "./DailyProgressBar";
import "./TopStatistics.css";
import Progress from "./Progress";
import useAuth from "../hooks/useAuth";

export function StreakBar({ progress: initialProgress }) {
    const [progress, setProgress] = useState([]);
    const { nativeLanguage } = useLanguage();

    useEffect(() => {
        if (!initialProgress) {
            return;
        }

        // get last 7 days
        let newProgress = initialProgress.slice(initialProgress.length - 7);

        newProgress = newProgress.map((stats, index) => {
            // Day in the format "2021-01-01"
            // We have to reformat it because JavaScript dates are fuuuucked and it gets interpreted as one day earlier
            const date = new Date(stats.day.replace(/-/g, "/"));
            const dayName = date.toLocaleString(nativeLanguage, { weekday: "long" });

            return {
                statistics: stats,
                dayName: dayName,
            };
        });
        console.log("Progress", newProgress);

        setProgress(newProgress);
    }, []);

    return (
        <StatisticsBar>
            <StatisticsRow>
                {progress.map((p, index) => (
                    <div
                        style={{
                            flexBasis: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "0.88rem",
                        }}>
                        <div>{p.dayName.substring(0, 1)}</div>
                        <div>
                            <StreakIcon
                                type={
                                    p.statistics.streak_freeze
                                        ? "freeze"
                                        : p.statistics.points_goal_met
                                        ? "streak"
                                        : index === 6
                                        ? "today"
                                        : null
                                }
                            />
                        </div>
                    </div>
                ))}
            </StatisticsRow>
        </StatisticsBar>
    );
}

const AbilityBadge = ({ icon, ability, label }) => {
    function level() {
        if (ability === null) {
            return strings.unknown_level;
        } else if (ability <= 1) {
            return "Beginner (A0)";
        } else if (ability <= 2) {
            return "Beginner (A1)";
        } else if (ability <= 3) {
            return "Beginner+ (A1-A2)";
        } else if (ability <= 4) {
            return "Beginner+ (A2)";
        } else if (ability <= 5) {
            return "Early Intermediate (A2-B1)";
        } else if (ability <= 6) {
            return "Intermediate (B1)";
        } else if (ability <= 7) {
            return "Intermediate+ (B2)";
        } else if (ability <= 8) {
            return "Early Advanced (B2)";
        } else if (ability <= 9) {
            return "Advanced (B2-C1)";
        } else {
            return "Advanced (C1+)";
        }
    }
    return (
        <div title={ability !== null ? ability.toFixed(2) : strings.unknown_level}>
            {icon} {label && label + ":"} {level()}
        </div>
    );
};

export const TopStatistics = ({ showAllLevels = false }) => {
    const { statistics, languageSettings } = useAuth();

    return (
        <>
            <h1>
                {strings.progress}
                <div style={{ display: "inline-block", float: "right" }}>
                    <DailyProgressBar points={statistics?.points} pointsGoal={languageSettings?.daily_points_goal} />
                </div>
            </h1>
            <div className="top-statistics">
                <div className={"top-statistics-bar"}>
                    <div>
                        <Flame weight="bold" className="solid-icon yellow" /> {statistics?.streak_days}{" "}
                        {strings.stats_streak}
                    </div>
                    <div>
                        <AirplaneTakeoff weight="bold" className="solid-icon yellow" /> {statistics?.points}/
                        {languageSettings?.daily_points_goal} {strings.points_today}
                    </div>

                    <div>
                        <Barbell weight="bold" className="solid-icon yellow" />{" "}
                        {statistics?.skill_vocab_pairs_studied?.toString()}/
                        {statistics?.skill_vocab_pairs_total?.toString()} {strings.skill_vocab_pairs_studied}
                    </div>
                    <div>
                        <UserSound weight="bold" className="solid-icon yellow" /> {statistics?.words_sent}{" "}
                        {strings.words_sent_title}
                    </div>
                    <div>
                        <AbilityBadge
                            icon={<Trophy weight="bold" className="solid-icon yellow" />}
                            ability={statistics?.estimated_ability}
                        />
                    </div>
                </div>
            </div>

            {showAllLevels && (
                <>
                    <h1>{strings.skills}</h1>
                    <div className="top-statistics">
                        <div className={"top-statistics-bar"}>
                            <div>
                                <AbilityBadge
                                    icon={<UserSound weight="bold" className="solid-icon yellow" />}
                                    ability={statistics?.estimated_speaking_ability}
                                    label="Speaking"
                                />
                            </div>
                            <div>
                                <AbilityBadge
                                    icon={<Ear weight="bold" className="solid-icon yellow" />}
                                    ability={statistics?.estimated_listening_ability}
                                    label="Listening"
                                />
                            </div>
                            <div>
                                <AbilityBadge
                                    icon={<Notebook weight="bold" className="solid-icon yellow" />}
                                    ability={statistics?.estimated_reading_ability}
                                    label="Reading"
                                />
                            </div>
                            <div>
                                <AbilityBadge
                                    icon={<Pen weight="bold" className="solid-icon yellow" />}
                                    ability={statistics?.estimated_writing_ability}
                                    label="Writing"
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
