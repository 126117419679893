import { Segment } from "semantic-ui-react";
import Skill from "../components/Skill";
import useTask from "../hooks/useTask";
import { strings } from "../utils/i18n.utils";
import TopicGuide from "./TopicGuide";

export const Topic = ({ topic, reloadTopic, nativeLanguage }) => {
    useTask(topic?.topic_guide?.generation_task_id, reloadTopic);

    return (
        <div>
            <TopicGuide topic={topic} nativeLanguage={nativeLanguage} />

            {topic.skills?.length > 0 && (
                <>
                    <h2>Skills</h2>
                    {topic.skills.map((skill) => (
                        <Skill key={skill.id} skill={skill} showOutcomes={true} />
                    ))}
                </>
            )}
        </div>
    );
};
