import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Input, Label, Loader, Segment } from "semantic-ui-react";
import { HomeTopBarPortal } from "../components/HomeTopBar";
import NextUp from "../components/NextUp";
import { TextEditor, TextEditorSegment } from "../components/TextEditor";
import { TopStatistics } from "../components/TopStatistics";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import useTask from "../hooks/useTask";
import useTitle from "../hooks/useTitle";
import { strings } from "../utils/i18n.utils";
import "./Home.css";
import { currentLocation } from "../utils/url.utils";
import { Gear } from "@phosphor-icons/react";

const AddSkills = () => {
    const { currentSpace } = useAuth();
    const history = useHistory();

    return (
        <>
            <h1>{strings.study_topic}</h1>
            <p>{strings.study_topic_description}</p>
            <Input
                placeholder={strings.activity_add_placeholder}
                style={{ width: "100%" }}
                onClick={() => history.push(`/spaces/${currentSpace.id}/topics/new?back=${currentLocation()}`)}></Input>
            <h1>{strings.study_notes}</h1>
            <p>{strings.study_notes_description}</p>
            <TextEditorSegment
                onClick={() => history.push(`/spaces/${currentSpace.id}/notes/new?back=${currentLocation()}`)}
                lines={4}>
                <TextEditor placeholder={"Write your note here..."} style={{ height: "100%" }} autoFocus={false} />
            </TextEditorSegment>
        </>
    );
};
const NextUpSegment = () => {
    const [planAPI, callPlanAPI] = useAPI();
    const { currentSpace } = useAuth();
    useTask(planAPI.response?.generation_task_id, loadPlan);

    function loadPlan() {
        callPlanAPI("GET", `/api/spaces/${currentSpace.id}/activity_feed`);
    }

    useEffect(() => {
        loadPlan();
    }, [currentSpace.id]);

    function removeActivityFromPlan(activity) {
        planAPI.setResponse((prev) => {
            const newActivities = prev.activities.filter((a) => a.id !== activity.id);

            return { ...prev, activities: newActivities };
        });
    }

    return (
        <div>
            {planAPI.loading && (
                <Segment>
                    <Loader active={true} inline={true} size="tiny" />
                </Segment>
            )}

            {!planAPI.loading && planAPI.response && (
                <NextUp plan={planAPI.response} removeActivity={removeActivityFromPlan} />
            )}
        </div>
    );
};

const News = () => {
    return (
        <>
            <h1>{strings.news}</h1>
            <p>
                Jetway is now smarter about what skills it automatically adds to your queue. Click the <Gear></Gear>{" "}
                Settings button at any time to remove stop practice an exercise and remove the skill from your queue.{" "}
            </p>
        </>
    );
};

const HomeContent = () => {
    const { statistics, currentSpace, setCurrentSpace } = useAuth();
    const [spaceAPI, callSpaceAPI] = useAPI();

    useEffect(() => {
        // reload user information
        callSpaceAPI("GET", `/api/spaces/${currentSpace.id}`);
    }, [currentSpace.id]);

    useEffect(() => {
        if (spaceAPI.response) {
            setCurrentSpace(spaceAPI.response);
        }
    }, [spaceAPI.response]);

    return (
        <div>
            <TopStatistics statistics={statistics} />
            <h1>{strings.itinerary}</h1>
            <NextUpSegment />
            <AddSkills />
        </div>
    );
};

export const AddActivityCollection = ({ addActivityCollection, onFinish }) => {
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            addActivityCollection(event.target.value).then(() => onFinish());
        }
    };
    return (
        <Input
            type="text"
            placeholder="Notebook name"
            autoFocus
            onKeyPress={handleKeyPress}
            onBlur={(e) => {
                if (e.target.value) {
                    addActivityCollection(e.target.value).then(() => onFinish());
                }
            }}
            style={{
                width: "100%",
            }}
        />
    );
};

export const Home = () => {
    useTitle(strings.home);

    return (
        <>
            <HomeTopBarPortal />

            <HomeContent />
        </>
    );
};
