import emojiRegex from "emoji-regex";
import React from "react";

const EmojiRegex = emojiRegex();

function withVariationSelector(text, color = false) {
    // Strip the presentation selectors from the string.
    const withoutPresentation = text.replaceAll(/(\uFE0F|\uFE0E)/gu, "");

    // Don't put selectors on color emojis. We let the fallback font handle that by putting Noto Color Emoji first in fallbacks.
    // That works better for Safari.
    if (color) return text;

    // Choose the appropriate presentation selector (FE0F is color, FE0E is mono).
    const selector = color ? "\uFE0F" : "\uFE0E";

    // Replace all sequences of emoji, emoji modifier bases, emoji modifiers, and zero width joiners.
    // Nearly arbitrary sequences of these can be composed to determine the presentation of a sequence
    // of code points, and they can be terminated by a presentation selector, so we match the whole
    // sequence, and append the selector to the end.
    // For the standard details, see: https://www.unicode.org/reports/tr51/
    return withoutPresentation.replaceAll(EmojiRegex, (match) => {
        return `${match}${selector}`;
    });
}

export function replaceEmojiWithSpan(text, color = false) {
    /* Turn the text into HTML where each emoji is wrapped in a span */

    return (
        text &&
        text.replace &&
        text.replace(emojiRegex(), (match) => {
            return `<span class="emoji-font">${withVariationSelector(match)}</span>`;
        })
    );
}

export function toCoolEmojis(text, color = true) {
    return React.createElement("span", {
        dangerouslySetInnerHTML: { __html: replaceEmojiWithSpan(text, color) },
    });
}
