import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Container } from "semantic-ui-react";
import ButtonBar from "../components/ButtonBar";
import LoadableButton from "../components/LoadableButton";
import Skill from "../components/Skill";
import { ZataLoader } from "../components/ZataLoader";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import useNotifications from "../hooks/useNotifications";
import useTask from "../hooks/useTask";
import useTitle from "../hooks/useTitle";
import { strings } from "../utils/i18n.utils";

const TopicInferScreen = ({}) => {
    const { topicId } = useParams();
    const { currentSpace } = useAuth();
    const history = useHistory();

    const [topic, setTopic] = useState(null);
    const [inferredSkills, setInferredSkills] = useState(null);
    const [topicAPI, callTopicAPI] = useAPI();
    const [taskResult] = useTask(topicAPI.response?.inferred_skills === null && topicAPI.response?.task_id);
    const { addNotification } = useNotifications();
    const [skillToSelectedPhraseIds, setSkillToSelectedPhraseIds] = useState({});
    const [addTopicToSpaceAPI, callAddTopicToSpaceAPI] = useAPI();

    useEffect(() => {
        if (taskResult && taskResult.task) {
            if (taskResult.task.status === "COMPLETED") {
                reloadTopic();
            } else {
                addNotification("inference", "Could not infer which skills you know. Please try again later.");
            }
        }
    }, [taskResult]);

    const { setPageTitle } = useTitle();

    const reloadTopic = () => {
        callTopicAPI("GET", `/api/spaces/${currentSpace.id}/topic_inferences/${topicId}`);
    };

    useEffect(() => {
        reloadTopic();
    }, [topicId]);

    function resetInferredSkills() {
        if (topicAPI.response.inferred_skills !== null) {
            if (topicAPI.response.inferred_skills.length === 0) {
                setInferredSkills([]);
            }

            for (const skill of topicAPI.response.inferred_skills) {
                setInferredSkills(topicAPI.response.inferred_skills);
                setSkillToSelectedPhraseIds((prev) => ({
                    ...prev,
                    [skill.id]: skill.vocab.map((phrase) => phrase.id),
                }));
            }
        }
    }

    function unselectAll() {
        setSkillToSelectedPhraseIds({});
    }

    useEffect(() => {
        if (topicAPI.response) {
            setPageTitle(topicAPI.response.topic.topic_native || topicAPI.response.topic.topic);
            setTopic(topicAPI.response.topic);

            resetInferredSkills();
        }
    }, [topicAPI.response]);

    function save() {
        const skills = Object.keys(skillToSelectedPhraseIds).map((skillId) => ({
            skill_id: parseInt(skillId),
            phrase_ids: skillToSelectedPhraseIds[skillId],
        }));

        callAddTopicToSpaceAPI("POST", `/api/spaces/${currentSpace.id}/topic_inferences/${topicId}/add`, {
            inferred_skills: skills,
        });
    }

    useEffect(() => {
        if (addTopicToSpaceAPI.response) {
            history.push(`/spaces/${currentSpace.id}/home`);
        }
    }, [addTopicToSpaceAPI.response]);

    if (topic === null) {
        return <ZataLoader />;
    }

    return (
        <div>
            <h1>{strings.topic_infer_title}</h1>
            {/*
        <p>{topic.topic} / {topic.topic_native}</p>
        */}
            {topicAPI.response.task_id && inferredSkills === null && (
                <ZataLoader message={strings.topic_infer_process} />
            )}

            {inferredSkills !== null && (
                <>
                    <ButtonBar>
                        <Button onClick={resetInferredSkills} content={strings.reset} compact />
                        <Button onClick={unselectAll} content={strings.unselect_all} compact />
                    </ButtonBar>

                    {topic.skills.map((skill) => (
                        <Skill
                            key={skill.id}
                            skill={skill}
                            selectable={true}
                            selectedPhraseIds={skillToSelectedPhraseIds[skill.id]}
                            onChange={(phrase, selected) => {
                                setSkillToSelectedPhraseIds((prev) => ({
                                    ...prev,
                                    [skill.id]: selected
                                        ? [...(prev[skill.id] || []), phrase.id]
                                        : (prev[skill.id] || []).filter((id) => id !== phrase.id),
                                }));
                            }}
                        />
                    ))}

                    {createPortal(
                        <Container>
                            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "1rem" }}>
                                <LoadableButton
                                    primary
                                    loading={addTopicToSpaceAPI.loading}
                                    content={strings.save}
                                    onClick={save}
                                />
                            </div>
                        </Container>,
                        document.getElementById("content-wrapper")
                    )}
                </>
            )}
        </div>
    );
};

export default TopicInferScreen;
