import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useGlobalAudioPlayer } from "react-use-audio-player";

export const AudioProviderContext = React.createContext({
    play: () => null,
    stop: () => null,
    isPlaying: false,
    loadAudio: () => console.log("Audio not loaded"),
    pause: () => null,
    queueAudioToPlay: () => null,
    playingAudioUrl: null,
    playStream: () => null,
});

export default function AudioProvider({ children }) {
    const [isPlaying, setPlaying] = useState();
    const { languageSettings } = useAuth();
    const history = useHistory();
    const playQueueRef = useRef([]);
    const playQueueIsPlayingRef = useRef(false);
    const isPlayingRef = useRef(isPlaying);
    const [playingAudioUrl, setPlayingAudioUrl] = useState();
    const { load, stop: internalStop, pause: internalPause } = useGlobalAudioPlayer();
    const sourceBufferRef = useRef(null);
    const mediaSourceRef = useRef(null);
    const lastChunkAppendedRef = useRef(false);
    const chunkQueueRef = useRef([]);
    const isProcessingQueueRef = useRef(false);

    function resetAudioState() {
        console.log("Audio: Resetting audio state");
        setPlaying(false);

        if (sourceBufferRef.current && mediaSourceRef.current) {
            try {
                // Check if media source is open before ending stream
                if (mediaSourceRef.current.readyState === "open") {
                    mediaSourceRef.current.endOfStream();
                }

                // Check if the sourceBuffer is actually in this mediaSource
                // MediaSource.sourceBuffers is a SourceBufferList
                const isSourceBufferAttached = Array.from(mediaSourceRef.current.sourceBuffers).includes(
                    sourceBufferRef.current
                );

                if (isSourceBufferAttached) {
                    mediaSourceRef.current.removeSourceBuffer(sourceBufferRef.current);
                }
                sourceBufferRef.current = null;
            } catch (e) {
                console.error("Error resetting audio state:", e);
                sourceBufferRef.current = null;
            }
        }

        if (mediaSourceRef.current) {
            URL.revokeObjectURL(mediaSourceRef.current);
            mediaSourceRef.current = null;
        }
    }
    useEffect(() => {
        history.listen((location, action) => {
            stop();
        });
    }, []);

    function stop() {
        console.log("Audio: stopping audio");
        internalStop();
        playQueueRef.current = [];
        playQueueIsPlayingRef.current = false;
        resetAudioState();
    }

    function queueAudioToPlay(url, clearQueue = false, playbackRate = 1.0) {
        console.log("Queueing audio to play", url, clearQueue, playbackRate);
        if (languageSettings.audio_enabled === false) {
            return;
        }

        if (clearQueue) {
            console.log("Audio: Clearing audio queue");
            stop();
            playQueueRef.current = [[url, playbackRate]];
        } else {
            playQueueRef.current.push([url, playbackRate]);
        }
        startPlayingQueue();
    }

    function startPlayingQueue() {
        if (!playQueueIsPlayingRef.current) {
            playQueueIsPlayingRef.current = true;
            playQueueNext();
        }
    }

    function playQueueNext() {
        if (playQueueRef.current.length > 0) {
            const [url, playbackRate] = playQueueRef.current.shift();
            console.log("Audio: Playing next in queue", url, playbackRate);
            play(url, playbackRate, () => {
                playQueueNext();
            });
        } else {
            playQueueIsPlayingRef.current = false;
        }
    }

    useEffect(() => {
        isPlayingRef.current = isPlaying;
    }, [isPlaying]);

    async function play(url, playbackRate = 1.0, onend = () => {}) {
        load(url, {
            playbackRate,
            format: "mp3",
            html5: true,
            autoplay: true,
            onplay: () => {
                setPlaying(true);
            },
            onend: () => {
                resetAudioState();
                onend();
            },
            onpause: () => {
                resetAudioState();
            },
        });

        setPlayingAudioUrl(url);
        setPlaying(true);
    }

    function playBase64(base64) {
        const audioChunk = base64ToArrayBuffer(base64);
        const blob = new Blob([audioChunk], { type: "audio/mp3" });
        const url = URL.createObjectURL(blob);

        return play(url);
    }

    // Utility function to convert base64 to ArrayBuffer
    function base64ToArrayBuffer(base64) {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }
    const playAudioChunk = (chunk) => {
        chunkQueueRef.current.push(chunk);
        if (!isProcessingQueueRef.current) {
            processQueue();
        }
    };

    const processQueue = () => {
        isProcessingQueueRef.current = true;
        const processNextChunk = () => {
            if (chunkQueueRef.current.length > 0) {
                const chunk = chunkQueueRef.current.shift();
                if (!sourceBufferRef.current) {
                    startAudioStream().then(() => {
                        appendChunk(chunk).then(processNextChunk);
                    });
                } else {
                    appendChunk(chunk).then(processNextChunk);
                }
            } else {
                isProcessingQueueRef.current = false;
                if (lastChunkAppendedRef.current) {
                    onUpdateEnd();
                }
            }
        };

        const appendChunk = (chunk) => {
            return new Promise((resolve) => {
                const audioChunk = base64ToArrayBuffer(chunk);
                const tryAppend = () => {
                    if (sourceBufferRef.current && !sourceBufferRef.current.updating) {
                        console.log("Audio: got chunk");
                        sourceBufferRef.current.appendBuffer(audioChunk);
                        sourceBufferRef.current.addEventListener("updateend", resolve, { once: true });
                    } else {
                        setTimeout(tryAppend, 50); // Retry after a short delay
                    }
                };
                tryAppend();
            });
        };

        processNextChunk();
    };

    const startAudioStream = () => {
        return new Promise((resolve) => {
            lastChunkAppendedRef.current = false;
            const mediaSource = new MediaSource();
            mediaSourceRef.current = mediaSource;
            mediaSource.addEventListener("sourceopen", () => {
                const sourceBuffer = mediaSource.addSourceBuffer("audio/mpeg");
                sourceBufferRef.current = sourceBuffer;
                sourceBuffer.addEventListener("updateend", onUpdateEnd);
                resolve();
            });
            const url = URL.createObjectURL(mediaSource);
            play(url)
                .then(() => {
                    console.log("Audio: started stream");
                })
                .catch((error) => {
                    console.error("Audio: error starting stream", error);
                });
        });
    };

    const endOfAudioChunks = () => {
        lastChunkAppendedRef.current = true;
        if (sourceBufferRef.current && !sourceBufferRef.current.updating) {
            endAudioStream();
        }
    };

    const onUpdateEnd = () => {
        if (lastChunkAppendedRef.current && chunkQueueRef.current.length === 0 && !sourceBufferRef.current.updating) {
            endAudioStream();
        }
    };

    const endAudioStream = () => {
        if (mediaSourceRef.current?.readyState === "open") {
            mediaSourceRef.current.endOfStream();
        }
        console.log("Audio: stream ended");
    };

    const contextValue = {
        play,
        stop,
        isPlaying,
        loadAudio: () => null,
        pause: internalPause,
        queueAudioToPlay,
        playingAudioUrl,
        playAudioChunk,
        endAudioStream: endOfAudioChunks,
        playBase64,
    };

    return <AudioProviderContext.Provider value={contextValue}>{children}</AudioProviderContext.Provider>;
}
