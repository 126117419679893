import { random } from "lodash";
import { useEffect } from "react";
import { createPortal } from "react-dom";
import { Link, useHistory } from "react-router-dom";
import { Icon, Segment, Sidebar as SemanticSidebar } from "semantic-ui-react";
import { useEventListener } from "../hooks/useEventListener";
import useSidebar from "../hooks/useSidebar";
import { strings } from "../utils/i18n.utils";
import "./Sidebar.css";

export const Sidebar = () => {
    const { showSidebar, setShowSidebar } = useSidebar();
    const history = useHistory();

    if (showSidebar) {
        document.getElementById("content-wrapper").classList.add("sidebar-open");
    } else {
        document.getElementById("content-wrapper").classList.remove("sidebar-open");
    }

    useEffect(() => {
        return history.listen((location, action) => {
            setShowSidebar(false);
        });
    });

    useEventListener("click", (e) => {
        // check if event target is part of the #content in the DOM
        if (showSidebar && e.target.closest("#content")) {
            setShowSidebar(false);
            // prevent any links from firing
            e.preventDefault();
        }
    });

    return createPortal(
        <div id="sidebar">
            <SemanticSidebar
                style={{ ...(window.Capacitor ? { paddingTop: "60px" } : {}) }}
                visible={showSidebar}
                direction="right"
                animation="push">
                <div className="SidebarContent">
                    <SidebarItem to={`/`} content={strings.home} />
                    {/*
                    <SidebarItem to={`/overview`} content={"Why Jetway?"} />
                    <SidebarItem to={`/teachers`} content={"For teachers"} />
                    */}
                    <SidebarItem to={`/about`} content={"About"} />
                    <SidebarItem to={"https://discord.gg/A3nSQEQZ6f"} content={"Discord"} />
                    <SidebarItem to={`/login`} content={strings.login} />
                </div>
            </SemanticSidebar>
        </div>,
        document.getElementById("sidebar-holder")
    );
};

const SidebarItem = ({ to, img, icon, content }) => {
    const history = useHistory();

    const linkTarget = {
        pathname: to.split("#")[0],
        key: random(), // we could use Math.random, but that's not guaranteed unique.
        state: {
            applied: true,
        },
    };

    if (to.includes("#")) {
        linkTarget.hash = to.split("#")[1];
    }

    return (
        <Segment
            key={to}
            as={Link}
            to={linkTarget}
            className={
                history.location.pathname === linkTarget.pathname && history.location.hash === linkTarget.hash
                    ? "SidebarItem Active"
                    : "SidebarItem Inactive"
            }>
            {icon && <Icon name={icon} />}
            &nbsp;
            {content}
        </Segment>
    );
};

export default Sidebar;
