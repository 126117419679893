import { useEffect, useState } from "react";
import { Button, Segment } from "semantic-ui-react";
import { strings } from "../utils/i18n.utils";
import MessageLimitIndicator from "./MessageLimitIndicator";
import { SpeechInputBar } from "./SpeechInputBar";
import { SendIcon } from "./SvgIcons";
import { TextEditor } from "./TextEditor";
import useAuth from "../hooks/useAuth";
import { useAudio } from "../hooks/useAudio";
import {
    Archive,
    ArrowArcRight,
    Brain,
    Check,
    EarSlash,
    Eye,
    Gear,
    Lifebuoy,
    Lightbulb,
    MicrophoneSlash,
    Prohibit,
    Repeat,
    SkipForward,
    ThumbsUp,
    UserSound,
} from "@phosphor-icons/react";

export const PromptInputBar = ({
    resetCount,
    textEditorRef,
    language,
    nativeLanguage,
    activityId,
    submit,
    onSpeechSubmit,
    onChange,
    disabled,
    isAwaitingLastMessage,
    onFocus,
    grammarCorrections,
    ignoredGrammarCorrections,
    setIgnoredGrammarCorrections,
    autoCapitalize,
    onApplyCorrection,
    sendCorrections,
    replacedValue,
    lastMessage,
    replyActions,
    replyActionType,
    textMode,
    sendCommand,
    loadingPrompt,
    revealCorrections,
}) => {
    const warning_limit = 350;
    const max_characters = 500;

    const [text, setText] = useState("");
    const [messageTooLong, setMessageTooLong] = useState(false);
    const { languageSettings } = useAuth();
    const { queueAudioToPlay } = useAudio();

    const internalOnChange = (text) => {
        setText(text);
        onChange && onChange(text);
    };

    function onPrimaryOptionlick(option) {
        if (option.command) {
            sendCommand(option.command, option.label);
        } else {
            if (option.phrase_id) {
                queueAudioToPlay(`/api/phrases/${option.phrase_id}/audio`, true);
            }

            submit(option.label);
        }
    }

    if (loadingPrompt) {
        return null;
    }

    return (
        <>
            {text.length >= warning_limit && (
                <MessageLimitIndicator
                    percentComplete={(text.length * 100) / max_characters}
                    remaining={max_characters - text.length}
                    message={strings.message_too_long}
                    setMessageTooLong={setMessageTooLong}
                />
            )}
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "0.5rem",
                    justifyContent: "center",
                    rowGap: "0.1rem",
                    columnGap: "0.5rem",
                }}>
                {replyActions !== null && replyActions.length > 0 && (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "0.5rem",
                            flexWrap: "wrap",
                            justifyContent: "center",
                        }}>
                        {replyActions.map((option) => (
                            <Button
                                key={"command-" + option.label}
                                onClick={() => onPrimaryOptionlick(option)}
                                disabled={disabled}
                                icon={
                                    {
                                        eye: <Eye weight="bold" />,
                                        brain: <Brain weight="bold" />,
                                        gear: <Gear weight="bold" />,
                                        archive: <Archive weight="bold" />,
                                        "step forward": <SkipForward weight="bold" />,
                                        repeat: <Repeat weight="bold" />,
                                        lifebouy: <Lifebuoy weight="bold" />,
                                        "ear-slash": <EarSlash weight="bold" />,
                                        "microphone-slash": <MicrophoneSlash weight="bold" />,
                                        check: <Check weight="bold" />,
                                        thumbsup: <ThumbsUp weight="bold" />,
                                        prohibit: <Prohibit weight="bold" />,
                                        "user-sound": <UserSound weight="bold" />,
                                        "arrow-arc-right": <ArrowArcRight weight="bold" />,
                                        lightbulb: <Lightbulb weight="bold" />,
                                    }[option.icon]
                                }
                                primary
                                content={
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: option.label,
                                        }}></span>
                                }></Button>
                        ))}
                    </div>
                )}
                {replyActions !== null && replyActions.length === 0 && (
                    <>
                        <div
                            className="editor-wrapper"
                            ref={textEditorRef}
                            style={{ flexGrow: 1, display: textMode ? null : "none" }}>
                            <Segment className="editor-segment">
                                <TextEditor
                                    resetCount={resetCount}
                                    disabled={disabled}
                                    placeholder={strings.type_answer_placeholder}
                                    initialText={replacedValue}
                                    language={language}
                                    nativeLanguage={nativeLanguage}
                                    activityId={activityId}
                                    onSubmit={submit}
                                    onChange={internalOnChange}
                                    grammarCorrections={revealCorrections ? grammarCorrections : []}
                                    ignoredGrammarCorrections={ignoredGrammarCorrections}
                                    setIgnoredGrammarCorrections={setIgnoredGrammarCorrections}
                                    sendCorrections={sendCorrections}
                                    autoCapitalize={autoCapitalize}
                                    onFocus={onFocus}
                                    onApplyCorrection={onApplyCorrection}
                                    autoFocus={true}
                                />
                            </Segment>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}>
                                <div
                                    style={{
                                        flexGrow: 4,
                                    }}></div>
                                <Button
                                    primary
                                    onClick={() => submit(text)}
                                    disabled={disabled || messageTooLong}
                                    style={{
                                        padding: "0.5rem",
                                        borderRadius: "50%",
                                        marginBottom: "0rem",
                                        margin: 0,
                                        width: "48px",
                                        height: "48px",
                                    }}>
                                    <SendIcon />
                                </Button>
                            </div>
                        </div>

                        {!textMode && (
                            <SpeechInputBar
                                onSpeechSubmit={onSpeechSubmit}
                                currentMessage={lastMessage}
                                language={language}
                                nativeLanguage={nativeLanguage}
                                autoTranscribe={languageSettings?.hands_free_mode_enabled}
                                loadingReply={isAwaitingLastMessage}
                            />
                        )}
                    </>
                )}
            </div>
        </>
    );
};
