import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import { Button, Divider, Input, Label, Segment, Tab } from "semantic-ui-react";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import { useLanguage } from "../hooks/useLanguage";
import { useQuery } from "../hooks/useQuery";
import useTask from "../hooks/useTask";
import useTitle from "../hooks/useTitle";
import { toCoolEmojis } from "../utils/emoji.utils";
import { strings } from "../utils/i18n.utils";
import { currentLocation } from "../utils/url.utils";
import "./AddTopic.css";
import ButtonBar from "./ButtonBar";
import { ContentWithLeft } from "./Flex";
import LoadableButton from "./LoadableButton";
import SegmentWithHeader from "./SegmentWithHeader";
import { ZataLoader } from "./ZataLoader";

export const AddTabs = ({ activeIndex }) => {
    const history = useHistory();
    const { currentSpace } = useAuth();
    const [internalActiveIndex, setActiveIndex] = useState(activeIndex);

    useEffect(() => {
        if (activeIndex !== internalActiveIndex) {
            if (internalActiveIndex === 0) {
                history.push(`/spaces/${currentSpace.id}/topics/new`);
            } else {
                history.push(`/spaces/${currentSpace.id}/notes/new`);
            }
        }
    }, [internalActiveIndex]);

    return (
        <Tab
            activeIndex={activeIndex}
            onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex)}
            panes={[
                {
                    menuItem: "Topic",
                    secondary: true,
                },
                {
                    menuItem: "Notes",
                    secondary: true,
                },
            ]}
            menu={{ secondary: true, pointing: true }}
            style={{ marginBottom: "1rem" }}></Tab>
    );
};

const Recommendations = () => {
    const [recommendationsAPI, callRecommendationsAPI] = useAPI();
    const { language, nativeLanguage } = useLanguage();
    const { currentSpace } = useAuth();

    useEffect(() => {
        callRecommendationsAPI("GET", `/api/spaces/${currentSpace.id}/recommendations`);
    }, [language, nativeLanguage]);

    if (recommendationsAPI.loading) {
        <ZataLoader message="Loading recommendations..." />;
    }

    return (
        <ButtonBar centered>
            {recommendationsAPI.response?.map((r) => (
                <Label
                    key={r.id}
                    as={Link}
                    basic
                    to={`/spaces/${currentSpace.id}/topics/new?searchText=${r.topic_native}`}>
                    {toCoolEmojis(r.emoji)} {r.topic_native ? r.topic_native : r.topic}
                </Label>
            ))}
        </ButtonBar>
    );
};

export const AddTopic = () => {
    const history = useHistory();
    const { setPageTitle } = useTitle();
    const { currentSpace } = useAuth();

    const [isSaving, setSaving] = useState(false);
    const [addTopicToSpaceAPI, callAddTopicToSpaceAPI] = useAPI({ loading: false });
    const [queryAPI, callQueryAPI, _, cancelQuery] = useAPI({ loading: false });
    const [searchText, setSearchText] = useState("");

    const [topicRequest, setTopicRequest] = useState(null);
    const [topic, setTopic] = useState();

    const query = useQuery();

    function next() {
        history.push(`/spaces/${currentSpace.id}/infer/${topic.id}?back=${currentLocation()}`);
    }

    const reloadTopic = () => {
        callQueryAPI(
            "GET",
            `/api/topics/${currentSpace.language}/${topic.topic}?native_language=${currentSpace.native_language}`
        );
    };

    useTask(topic?.generation_task_id, reloadTopic);

    useEffect(() => {
        const request = {
            topic: query.get("searchText"),
            language: currentSpace.language,
            native_language: currentSpace.native_language,
        };

        setTopicRequest(request);
        if (searchText !== request.topic) {
            setSearchText(request.topic);
        }
    }, [window.location, currentSpace, query]);

    useEffect(() => {
        // update the url with the search text
        if (topicRequest) {
            if (searchText !== topicRequest.topic) {
                history.replace(`/spaces/${currentSpace.id}/topics/new?searchText=${searchText}`);
                setTopic(null);
            }
        }
    }, [searchText]);

    useEffect(() => {
        setPageTitle(strings.topic_add);
    }, [currentSpace]);

    function navigateHome() {
        history.push(`/spaces/${currentSpace.id}/skills`);
    }

    useEffect(() => {
        if (addTopicToSpaceAPI.response) {
            navigateHome();
        }
    }, [addTopicToSpaceAPI.response]);

    function clearQueryResults() {
        queryAPI.setLoading(false);
        cancelQuery();
    }

    useEffect(() => {
        if (topicRequest !== null && (topicRequest.topic || topicRequest.id)) {
            clearQueryResults();
            queryAPI.setLoading(true);
            const debounce = setTimeout(() => {
                if (topicRequest.id) {
                    callQueryAPI(
                        "GET",
                        `/api/topics/${currentSpace.language}/${topicRequest.topic_name}?native_language=${currentSpace.native_language}`
                    );
                } else if (topicRequest.topic) {
                    callQueryAPI("POST", `/api/topics`, topicRequest);
                }
            }, 750);
            return () => clearTimeout(debounce);
        } else {
            clearQueryResults();
        }
    }, [topicRequest]);

    useEffect(() => {
        if (queryAPI.response) {
            setTopic(queryAPI.response);
        }
    }, [queryAPI.response]);

    return (
        <div>
            <AddTabs activeIndex={0} />
            <div>
                <p>{strings.study_topic_description}</p>
                <Input
                    placeholder={strings.activity_add_placeholder}
                    focus
                    style={{ width: "100%" }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    autoFocus></Input>

                {(searchText === null || searchText === "") && (
                    <div>
                        <Divider />
                        <Recommendations setTopic={setTopic} />
                    </div>
                )}
            </div>

            {(queryAPI.loading || topic?.generation_task_id) && (
                <Segment>
                    <p>{strings.topic_building}</p>
                </Segment>
            )}

            {!queryAPI.loading && topic?.skills?.length > 0 && (
                <SegmentWithHeader
                    title={
                        <>
                            {topic.topic}
                            <p style={{ fontSize: "0.8rem" }}>{topic.topic_native}</p>
                        </>
                    }>
                    <ul>
                        {topic.skills.map((skill) => (
                            <li>{skill.name_native}</li>
                        ))}
                    </ul>
                    <div style={{ margin: "0.5rem" }} />
                    <ButtonBar multirow={false}>
                        <LoadableButton content={strings.add} onClick={next} loading={isSaving} primary />
                        <Button
                            content={strings.guide}
                            as={Link}
                            to={`/spaces/${currentSpace.id}/topics/${topic.topic}?back=${currentLocation()}`}
                        />
                    </ButtonBar>
                </SegmentWithHeader>
            )}
        </div>
    );
};

export const CreateYoutubeCollection = ({ setTopicRequest }) => {
    const [mediaUrl, setMediaUrl] = useState("");
    const [mediaAPI, callMediaAPI] = useAPI({
        loading: false,
        ignoredStatusCodes: ["unrecognized_video_url", "unsupported_language"],
    });

    const [refreshMediaAPI, callRefreshMediaAPI] = useAPI({
        loading: false,
    });
    const [media, setMedia] = useState(null);
    const { language, nativeLanguage } = useLanguage();

    useEffect(() => {
        setTopicRequest(null);
        setMedia(null);

        if (mediaUrl === "") {
            return;
        }

        const debounce = setTimeout(() => {
            callMediaAPI("POST", "/api/media", {
                media_url: mediaUrl,
                language: language,
            });
        }, 750);
        return () => clearTimeout(debounce);
    }, [mediaUrl]);

    useEffect(() => {
        if (mediaAPI.response) {
            setMedia(mediaAPI.response);
            callRefreshMediaAPI("GET", `/api/media/${mediaAPI.response.id}`);
            setTopicRequest({
                language: language,
                native_language: nativeLanguage,
                media_id: mediaAPI.response.id,
            });
        }
    }, [mediaAPI.response]);

    useEffect(() => {
        if (refreshMediaAPI.response) {
            setMedia(refreshMediaAPI.response);
        }
    }, [refreshMediaAPI.response]);

    function truncate(str, n) {
        return str.length > n ? str.substr(0, n - 1) + "..." : str;
    }

    return (
        <div>
            <p>
                <Input
                    placeholder={"YouTube Video URL"}
                    focus
                    style={{ width: "100%" }}
                    value={mediaUrl}
                    onChange={(e) => setMediaUrl(e.target.value)}></Input>
            </p>

            {mediaAPI.loading && (
                <Segment>
                    <ZataLoader message="Loading video..." />
                </Segment>
            )}
            {mediaAPI.error && <p>{strings["youtube_" + mediaAPI.error.code]}</p>}

            {refreshMediaAPI.loading && (
                <Segment>
                    <ZataLoader message="Analyzing video..." />
                </Segment>
            )}
            {!mediaAPI.error && media && (
                <div>
                    <Segment>
                        <h2>{media.title}</h2>
                        <ContentWithLeft left={<img src={media.thumbnail_url} />}>
                            <p>{truncate(media.description, 300)}</p>
                        </ContentWithLeft>
                    </Segment>
                    {media.key_sentences?.length > 0 && (
                        <Segment>
                            <h2>Key Sentences</h2>
                            <ul>
                                {media.key_sentences?.map((e) => (
                                    <li>{e}</li>
                                ))}
                            </ul>
                        </Segment>
                    )}
                    {media.vocabulary?.length > 0 && (
                        <Segment>
                            <h2>Key Expressions</h2>
                            <ul>
                                {media.vocabulary?.map((e) => (
                                    <li>{e}</li>
                                ))}
                            </ul>
                        </Segment>
                    )}
                    {media.discussion_questions?.length > 0 && (
                        <Segment>
                            <h2>Discussion questions</h2>
                            <ul>
                                {media.discussion_questions?.map((e) => (
                                    <li>{e}</li>
                                ))}
                            </ul>
                        </Segment>
                    )}
                </div>
            )}
        </div>
    );
};
