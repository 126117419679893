import { Alarm, Archive, DotsThreeVertical, Flag, Info, Lifebuoy } from "@phosphor-icons/react";
import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Button, Dropdown, DropdownMenu, Label, Segment } from "semantic-ui-react";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import { useLanguage } from "../hooks/useLanguage";
import { strings } from "../utils/i18n.utils";
import { timeSince } from "../utils/time.utils";
import { currentLocation } from "../utils/url.utils";
import "./ActivityDetails.css";
import EmojiIcon from "./EmojiIcon";
import Phrase from "./Phrase";
import SegmentWithHeader from "./SegmentWithHeader";
import { SkillComponentDetailsPopup } from "./SkillComponentDetails";
import SkillSegment from "./SkillSegment";
export const ActivitySegment = ({ activity, showStart = true, onArchive }) => {
    const { statistics, currentSpace } = useAuth();
    const [deleteAPI, callDeleteAPI] = useAPI({ loading: false });

    function archiveActivity() {
        callDeleteAPI("PUT", `/api/activities/${activity.id}`, {
            archived: !activity.archived,
            unarchive_activity_collection: activity.archived,
        });
    }

    useEffect(() => {
        if (deleteAPI.response) {
            onArchive && onArchive(activity.id);
        }
    });

    return (
        <SegmentWithHeader
            actions={
                <>
                    <Button
                        content={activity.last_session?.status === "in_progress" ? strings.resume : strings.start}
                        as={Link}
                        to={`/spaces/${currentSpace.id}/chat?activityId=${activity.id}`}
                        compact
                    />
                </>
            }
            title={
                activity.type === "review" ? (
                    <Link to={`/spaces/${currentSpace.id}/activities/${activity.id}?back=${currentLocation()}`}>
                        {statistics?.skill_components_needing_review} {strings.flashcards_needing_review}
                    </Link>
                ) : (
                    <Link to={`/spaces/${currentSpace.id}/activities/${activity.id}?back=${currentLocation()}`}>
                        {activity.name_native}
                        {/*
                        &nbsp;
                        {activity.type == "lesson" && <Label basic>{strings.activity_type_lesson}</Label>}
                        {activity.type == "tutor" && <Label basic>{strings.activity_type_tutor}</Label>}
                        {activity.type == "role_play" && <Label basic>{strings.activity_type_role_play}</Label>}
                        */}
                    </Link>
                )
            }
            icon={
                <EmojiIcon
                    emoji={activity.emoji}
                    backgroundColor={
                        {
                            tutor: "yellow",
                            role_play: "yellow",
                            discuss: "yellow",
                            comprehension: "black",
                            talk: "black",
                        }[activity.type] || "blue"
                    }
                />
            }
            disabled={true || activity.blocked}></SegmentWithHeader>
    );
};
/*
<SegmentWithHeader
                    actions={
                        <Button
                            content={strings.start}
                            as={Link}
                            to={`/spaces/${currentSpace.id}/chat?activityType=review`}
                            compact
                        />
                    }
                    title={
                        <Link to={`/spaces/${currentSpace.id}/exercises`}>
                            {statistics?.skill_components_needing_review} {strings.flashcards_needing_review}
                        </Link>
                    }
                    icon={<Alarm weight="bold" className="solid-icon" />}
                />
                */

export const Activity = ({ activity, onArchive, showActivityType = false }) => {
    const [deleteAPI, callDeleteAPI] = useAPI({ loading: false });
    const { currentSpace } = useAuth();

    function deleteActivity() {
        callDeleteAPI("DELETE", `/api/activities/${activity.id}`);
    }

    useEffect(() => {
        if (deleteAPI.response) {
            onArchive && onArchive(deleteActivity);
        }
    }, [deleteAPI.response]);

    return (
        <div className="Activity">
            <Link to={`/spaces/${currentSpace.id}/chat?activityId=${activity.id}`}>
                {activity.emoji} {activity.name_native ? activity.name_native : activity.name}
            </Link>
            {/*
            <div>
                {activity.last_session &&
                    activity.last_session.activity_mastered !== true &&
                    activity.last_session.skills_mastered !== null &&
                    activity.last_session.progress !== null &&
                    activity.last_session.progress !== 0 &&
                    activity.last_session.progress !== 1 && (
                        <Progress value={activity.last_session.progress} total={1} size="tiny" />
                    )}
            </div>
            <div className="wrapping-label-container">
                <ActivityTypeLabel type={activity.type} />
                {activity.last_session?.activity_mastered === true && (
                    <Label basic size="small" className="completed">
                        Completed
                    </Label>
                )}
                {activity.statistics?.flashcards_needing_review > 0 && (
                    <Label basic size="small">
                        📝 {activity.statistics?.flashcards_needing_review}
                    </Label>
                )}
            </div>
            */}
        </div>
    );
};

export const ActivitySkills = ({ skills }) => {
    return (
        <>
            {(skills === null || skills.length === 0) && <p>{strings.notes_analyze_empty_state}</p>}

            {skills?.map((skill) => (
                <SkillSegment skill={skill} />
            ))}
        </>
    );
};

export const Flashcard = ({ flashcard, phrase, onDelete }) => {
    let { nativeLanguage } = useLanguage();

    return (
        <Segment>
            <Phrase
                phrase={phrase}
                activityId={flashcard.activity_id}
                blanks={["cloze", "multiple_choice"].includes(flashcard.type) ? flashcard.cloze_answer_word_ids : null}
                allowFullTranslation={true}
                translationLanguage={nativeLanguage}
                additionalActions={<FlashcardActions flashcard={flashcard} onDelete={onDelete} />}
            />
            <Label basic size="small">
                {strings["prompt_exercise_type_" + flashcard.type]}
            </Label>
            {flashcard.next_review && !flashcard.next_review?.startsWith("9999") && (
                <Label basic size="small">
                    <Alarm />{" "}
                    {new Date(flashcard.next_review) > Date.now()
                        ? timeSince(flashcard.next_review)
                        : "Ready for Review"}
                </Label>
            )}
            {flashcard.blocked === true && (
                <Label basic size="small">
                    <Flag /> Blocked
                </Label>
            )}
            <SkillComponentDetailsPopup
                trigger={
                    <Label basic size="small">
                        <Lifebuoy />
                    </Label>
                }
                flashcard={flashcard}
            />
        </Segment>
    );
};

export const FlashcardActions = ({ flashcard, onDelete }) => {
    const [deleteAPI, callDeleteAPI] = useAPI({ loading: false });

    function deleteFlashcard(flashcardId) {
        callDeleteAPI("DELETE", `/api/flashcards/${flashcardId}`);
    }

    useEffect(() => {
        if (deleteAPI.response && onDelete) {
            onDelete(flashcard);
        }
    }, [deleteAPI.response]);

    if (deleteAPI.loading) {
        return <p>Deleting...</p>;
    }

    return (
        <>
            <Button
                compact
                icon={<Archive />}
                size={"small"}
                content={strings.archive}
                onClick={() => deleteFlashcard(flashcard.id)}
            />
        </>
    );
};
