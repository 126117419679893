import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Topic } from "../components/Topic";
import { ZataLoader } from "../components/ZataLoader";
import useAPI from "../hooks/useAPI";
import useTitle from "../hooks/useTitle";
import { strings } from "../utils/i18n.utils";

const TopicScreen = ({}) => {
    const { language, topicName, nativeLanguage } = useParams();
    const [topicAPI, callTopicAPI] = useAPI();

    const { setPageTitle, setNavigationTitle, setDescription } = useTitle();

    const reloadTopic = () => {
        callTopicAPI("GET", `/api/topics/${language}/${topicName}?native_language=${nativeLanguage}&include_help=true`);
    };

    useEffect(() => {
        reloadTopic();
    }, [language, topicName]);

    useEffect(() => {
        if (topicAPI.response) {
            setPageTitle(
                (topicAPI.response.topic_native || topicAPI.response.topic) +
                    " - Learn to talk in " +
                    strings[topicAPI.response.language] +
                    " about anything - "
            );
            setDescription(
                `Learn to speak in ${strings[topicAPI.response.language]} about: ` +
                    topicAPI.response.skills.map((skill) => skill.name).join(", ")
            );
        }
    }, [topicAPI.response]);

    if (topicAPI.response === null || topicAPI.loading) {
        return <ZataLoader />;
    }

    return (
        <div>
            <h1>
                {topicAPI.response.topic_native} - {strings[topicAPI.response.language]}
                {/*
                <Row align="right">
                    <Button
                        onClick={() =>
                            history.push(
                                `/spaces/${currentSpace.id}/topics/${topicAPI.response.topic}&back=${currentLocation()}`
                            )
                        }
                        icon={<Plus />}
                        content={strings.add}
                    />
                </Row>
                */}
            </h1>
            <p style={{ fontSize: "0.8rem" }}>{topicAPI.response.topic}</p>
            <Topic topic={topicAPI.response} reloadTopic={reloadTopic} nativeLanguage={nativeLanguage} />;
        </div>
    );
};

export default TopicScreen;
