import { Interweave } from "interweave";
import { LazyPhrase } from "./LazyPhrase";

const HelpText = ({ content, language, hideWords, wordsUnhidden }) => {
    function transform(node, children) {
        if (node.tagName === "PHRASE") {
            return (
                <LazyPhrase text={children} language={language} hideWords={hideWords} wordsUnhidden={wordsUnhidden} />
            );
        } else if (node.tagName === "CODE") {
            return (
                <LazyPhrase
                    text={children[0]}
                    language={language}
                    hideWords={hideWords}
                    wordsUnhidden={wordsUnhidden}
                />
            );
        }
    }

    return <Interweave transform={transform} content={content} />;
};

export default HelpText;
