import { Modal, Popup, Segment } from "semantic-ui-react";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import { strings } from "../utils/i18n.utils";
import { ZataLoader } from "./ZataLoader";
import { useEffect, useState } from "react";
import { timeSince } from "../utils/time.utils";

export const SkillComponentDetails = ({ skillComponentId }) => {
    const [historyAPI, callHistoryAPI] = useAPI();
    const { currentSpace } = useAuth();
    const [skillComponent, setSkillComponent] = useState();
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        callHistoryAPI("GET", `/api/spaces/${currentSpace.id}/skill_components/${skillComponentId}/history`);
    }, [skillComponentId]);

    useEffect(() => {
        if (historyAPI.response) {
            setSkillComponent(historyAPI.response.skill_component);
            setReviews(historyAPI.response.skill_component_reviews);
        }
    });

    if (!skillComponent || historyAPI.loading) {
        return <ZataLoader />;
    }

    return (
        <div>
            <div>{skillComponent.id}</div>

            <div>Skill: {skillComponent.skill?.name}</div>
            <div>Word: {skillComponent.vocab_phrase?.text}</div>
            <div>Type: {skillComponent.type}</div>
            <div>Due: {reviews[0].start_date && timeSince(new Date(Date.parse(reviews[0].start_date)))}</div>

            <h2>{strings.history}</h2>

            {reviews.map((review) => (
                <Segment key={review.id}>
                    <div>{review.id}</div>
                    {review.flashcard_review && (
                        <div>
                            <div>{review.flashcard_review?.flashcard?.phrase.text}</div>
                            <div>{review.flashcard_review.user_answer}</div>
                            {review.flashcard_review?.flashcard?.difficulty && (
                                <div>Difficulty: {review.flashcard_review?.flashcard?.difficulty}</div>
                            )}
                        </div>
                    )}
                    {review.complete_date ? (
                        <div>{timeSince(new Date(Date.parse(review.complete_date)))}</div>
                    ) : (
                        <div>Due: {timeSince(new Date(Date.parse(review.start_date)))}</div>
                    )}
                    <div>{review.correct !== null && (review.correct ? "Correct" : "Incorrect")}</div>
                </Segment>
            ))}
        </div>
    );
};

export const SkillComponentDetailsPopup = ({ trigger, skillComponentId }) => {
    return (
        <Modal
            trigger={trigger}
            header="Exercise History"
            closeIcon={true}
            content={<SkillComponentDetails skillComponentId={skillComponentId} />}
        />
    );
};

export default SkillComponentDetails;
