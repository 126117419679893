import { useEffect, useState } from "react";
import useAPI from "../hooks/useAPI";
import useTask from "../hooks/useTask";
import "./ActivityHelpModal.css";
import HelpText from "./HelpText";
import { ZataLoader } from "./ZataLoader";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const TopicGuide = ({ topic, setTopic, nativeLanguage }) => {
    const [topicHelpAPI, callTopicAPI] = useAPI({ loading: false });
    const [helpText, setHelpText] = useState(topic.guide?.markdown);
    const [taskResult] = useTask(topicHelpAPI.response?.guide?.generation_task_id);

    useEffect(() => {
        if (!helpText || topicHelpAPI.response?.guide?.generation_task_id) {
            checkForHelp();
        }
    }, [topic]);

    function checkForHelp(refresh = false) {
        callTopicAPI(
            "GET",
            `/api/topics/${topic.language}/${topic.topic}?include_help=true&refresh_help=${refresh}&native_language=${nativeLanguage}`
        );
    }

    useEffect(() => {
        if (taskResult && taskResult.task.status === "COMPLETED") {
            callTopicAPI(
                "GET",
                `/api/topics/${topic.language}/${topic.topic}?include_help=true&native_language=${nativeLanguage}`
            );
        }
    }, [taskResult]);

    useEffect(() => {
        if (topicHelpAPI.response?.guide?.markdown) {
            setHelpText(topicHelpAPI.response.guide?.markdown);
        }

        if (topicHelpAPI.response?.guide?.generation_task_id) {
            let debounce = setTimeout(() => {
                checkForHelp(false);
            }, 1000);

            return () => clearTimeout(debounce);
        }
    }, [topicHelpAPI.response]);

    return (
        <>
            {helpText && <HelpText content={helpText} language={topic.language} />}

            {((topicHelpAPI.loading && !topicHelpAPI.response?.guide?.markdown) ||
                topicHelpAPI.response?.guide?.generation_task_id) && <ZataLoader />}
        </>
    );
};

export default TopicGuide;
