import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useHistory } from "react-router-dom";
import { Button, Container, Divider, Input, Label } from "semantic-ui-react";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import { useLanguage } from "../hooks/useLanguage";
import { useQuery } from "../hooks/useQuery";
import { allStrings, strings } from "../utils/i18n.utils";
import { ClickableSegment } from "../components/ClickableSegment";
import { ZataLoader } from "../components/ZataLoader";
import "./Start.css";
import ButtonBar from "../components/ButtonBar";
import LoadableButton from "../components/LoadableButton";
import SelectableLabel from "../components/SelectableLabel";
import { toCoolEmojis } from "../utils/emoji.utils";

const nativeLanguages = {
    en: "I speak English",
    "es-mx": "Hablo español (Mexico)",
    "es-es": "Hablo español (España)",
    fr: "Je parle français",
    de: "Ich spreche Deutsch",
    it: "Parlo italiano",
    "pt-pt": "Eu falo português (Europeu)",
    "pt-br": "Eu falo português (Brasileiro)",
    uk: "Я розмовляю українською",
    ru: "Я говорю по-русски",
};

export const StartProgressBar = ({ steps, activeStep }) => {
    const percentDone = (activeStep / steps) * 100;

    return createPortal(
        <Container>
            <div className="ui tiny progress" data-percent={`${percentDone}`}>
                <div className="bar" style={{ width: `${percentDone}%` }}></div>
                <div className="label">
                    {activeStep + 1} / {steps}
                </div>
                <div style={{ marginBottom: "2rem" }}></div>
            </div>
        </Container>,
        document.getElementById("topbar")
    );
};

export const SelectNativeLanguage = ({ next, prev, space, setSpace }) => {
    function setNativeLanguage(nativeLanguage) {
        setSpace({ ...space, native_language: nativeLanguage });
        next();
    }

    return (
        <div>
            {Object.keys(nativeLanguages).map((key) => (
                <ClickableSegment key={key} onClick={() => setNativeLanguage(key)}>
                    {nativeLanguages[key]}
                </ClickableSegment>
            ))}

            <NextPrevButtons space={space} onPrev={prev} />
        </div>
    );
};

const SelectLanguage = ({ next, prev, space, setSpace }) => {
    function setLanguage(language) {
        setSpace({ ...space, language, name: allStrings[space.native_language][language] });
        next();
    }

    return (
        <div>
            <p>
                <b>{allStrings[space.native_language].i_want_to_learn}.</b>
            </p>
            {Object.keys(nativeLanguages)
                .filter((key) => key !== space.native_language && !["uk"].includes(key))
                .map((key) => (
                    <ClickableSegment key={key} onClick={() => setLanguage(key)}>
                        {allStrings[space.native_language][key]}
                    </ClickableSegment>
                ))}

            <NextPrevButtons space={space} onPrev={prev} />
        </div>
    );
};

const SelectLevel = ({ next, prev, space, setSpace }) => {
    const [createSpaceAPI, callCreateSpaceAPI] = useAPI();
    const [levels, setLevels] = useState([]);

    function setLevel(level) {
        const newSpace = { ...space, self_reported_cefr_level: level };
        setSpace(newSpace);
        callCreateSpaceAPI("POST", "/api/spaces", newSpace);
    }

    useEffect(() => {
        if (createSpaceAPI.response) {
            setSpace(createSpaceAPI.response);
            next();
        }
    }, [createSpaceAPI.response]);

    useEffect(() => {
        setLevels([
            { key: "A1", message: allStrings[space.native_language].level_a1_description },
            { key: "A2", message: allStrings[space.native_language].level_a2_description },
            { key: "B1", message: allStrings[space.native_language].level_b1_description },
            { key: "B2", message: allStrings[space.native_language].level_b2_description },
        ]);
    }, []);

    return (
        <div>
            <p>
                <b>
                    {strings.formatString(
                        allStrings[space.native_language].level_question,
                        space.language + "_with_article" in allStrings[space.native_language]
                            ? allStrings[space.native_language][space.language + "_with_article"]
                            : allStrings[space.native_language][space.language]
                    )}
                </b>
            </p>
            {levels.map((level) => (
                <ClickableSegment onClick={() => setLevel(level.key)} key={level.key}>
                    <b>{allStrings[space.native_language][level.key.toLowerCase() + "_description_and_cefr"]}</b>
                    <p>{level.message}</p>
                </ClickableSegment>
            ))}

            <NextPrevButtons space={space} onPrev={prev} />
        </div>
    );
};

const SetName = ({ prev, next, space }) => {
    const [userAPI, callUserAPI] = useAPI();
    const { user, setUser } = useAuth();
    const [name, setName] = useState(user.name);

    useEffect(() => {
        user.name = name;
        setUser(user);
    }, [name]);

    const saveUser = () => {
        callUserAPI("PUT", "/api/user", {
            name: user.name,
        });
    };

    useEffect(() => {
        if (userAPI.response) {
            setUser(userAPI.response);
            next();
        }
    }, [userAPI.response]);

    return (
        <div>
            <div style={{ marginBottom: "0.5rem" }}>
                <label>{allStrings[space.native_language].my_name_is}</label>
            </div>

            <Input value={name} onChange={(e) => setName(e.target.value)} autoFocus />

            <NextPrevButtons space={space} onNext={saveUser} isNextLoading={userAPI.loading} onPrev={prev} />
        </div>
    );
};

const SelectTopics = ({ next, prev, space }) => {
    const [recommendationsAPI, callRecommendationsAPI] = useAPI();
    const [saveRecommendationsAPI, callSaveRecommendationsAPI] = useAPI();
    const { language, nativeLanguage } = useLanguage();
    const [selectedRecommendations, setSelectedRecommendations] = useState([]);

    function selectRecommendation(recommendation) {
        if (selectedRecommendations.includes(recommendation)) {
            setSelectedRecommendations(selectedRecommendations.filter((r) => r !== recommendation));
        } else {
            setSelectedRecommendations([...selectedRecommendations, recommendation]);
        }
    }

    useEffect(() => {
        callRecommendationsAPI("GET", `/api/spaces/${space.id}/recommendations`);
    }, [language, nativeLanguage]);

    const saveRecommendations = () => {
        callSaveRecommendationsAPI("POST", `/api/spaces/${space.id}/recommendations/accept`, {
            recommendation_ids: selectedRecommendations.map((r) => r.id),
            native_language: nativeLanguage,
        });
    };

    useEffect(() => {
        if (saveRecommendationsAPI.response) {
            next();
        }
    }, [saveRecommendationsAPI.response]);

    return (
        <div>
            <div style={{ marginBottom: "0.5rem" }}>
                <label>{allStrings[space.native_language].start_select_recommendations}</label>
            </div>
            <Divider />
            {recommendationsAPI.loading && <ZataLoader />}

            <ButtonBar centered>
                {recommendationsAPI.response?.map((r) => (
                    <SelectableLabel
                        key={r.id}
                        basic={true}
                        size={"medium"}
                        label={
                            <span>
                                {toCoolEmojis(r.emoji)} {r.topic_native ? r.topic_native : r.topic}
                            </span>
                        }
                        onClick={() => selectRecommendation(r)}
                        selected={selectedRecommendations.includes(r)}
                    />
                ))}
            </ButtonBar>

            <NextPrevButtons
                space={space}
                onDone={saveRecommendations}
                onPrev={prev}
                isDoneDisabled={selectedRecommendations.length < 1}
                isDoneLoading={saveRecommendationsAPI.loading}
            />
        </div>
    );
};

const NextPrevButtons = ({
    onNext,
    isNextDisabled,
    isNextLoading,
    onDone,
    isDoneLoading,
    isDoneDisabled,
    onPrev,
    space,
}) => {
    return (
        <>
            <Divider />

            {createPortal(
                <div id="bottombar">
                    <Container>
                        <ButtonBar centered={true}>
                            {onPrev && (
                                <Button
                                    onClick={onPrev}
                                    content={allStrings[space.native_language].previous}
                                    style={{ minWidth: "10rem" }}
                                />
                            )}

                            {onNext && (
                                <LoadableButton
                                    primary
                                    onClick={onNext}
                                    content={allStrings[space.native_language].next}
                                    loading={isNextLoading}
                                    disabled={isNextDisabled}
                                    style={{ minWidth: "10rem" }}
                                />
                            )}

                            {onDone && (
                                <LoadableButton
                                    primary
                                    onClick={onDone}
                                    content={allStrings[space.native_language].done}
                                    loading={isDoneLoading}
                                    disabled={isDoneDisabled}
                                    style={{ minWidth: "10rem" }}
                                />
                            )}
                        </ButtonBar>
                    </Container>
                </div>,
                document.getElementById("content-wrapper")
            )}
        </>
    );
};

export const Start = () => {
    const [step, setStep] = useState(0);
    const history = useHistory();
    const { user, setUser } = useAuth();
    const query = useQuery();
    const [space, setSpace] = useState({});
    const { switchSpace } = useAuth();

    console.log("Space", space, step);

    const next = () => {
        if (step === 4) {
            const redirectTo = query.get("redirectTo");
            if (redirectTo && redirectTo !== "") {
                history.push(redirectTo);
            } else {
                history.push(`/spaces/${space.id}/home`);
                setUser({ ...user, all_spaces: [...user.all_spaces, space] });
                console.log("Switching to space", space.id);
                switchSpace(space.id);
            }
        } else {
            setStep(step + 1);
        }
    };

    const prev = () => {
        setStep(step - 1);
    };

    const stepComponents = [
        <SelectNativeLanguage next={next} space={space} setSpace={setSpace} />,
        <SelectLanguage next={next} prev={prev} space={space} setSpace={setSpace} />,
        <SelectLevel next={next} prev={prev} space={space} setSpace={setSpace} />,
        <SetName next={next} prev={prev} space={space} setSpace={setSpace} />,
        <SelectTopics next={next} prev={prev} space={space} setSpace={setSpace} />,
    ];

    return (
        <Container
            style={{
                display: "flex",
                flexDirection: "column",
            }}>
            <StartProgressBar steps={stepComponents.length} activeStep={step} />

            {stepComponents[step]}
        </Container>
    );
};

export const SelectLevelStandalone = () => {
    const history = useHistory();
    const { currentSpace } = useAuth();

    function next() {
        history.push(`/spaces/${currentSpace.id}/home`);
    }

    return (
        <Container
            style={{
                display: "flex",
                flexDirection: "column",
            }}>
            <SelectLevel next={next} />
        </Container>
    );
};
