import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useAPI from "../hooks/useAPI";
import { strings } from "../utils/i18n.utils";
import { Link } from "react-router-dom/cjs/react-router-dom";

const TopicsByCategoryScreen = () => {
    const { language, nativeLanguage } = useParams();
    const [topicsAPI, callTopicsAPI] = useAPI({ loading: true });
    const [topicsByCategory, setTopicsByCategory] = useState([]);
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        let url = `/api/topics_by_category/${nativeLanguage}/${language}`;

        callTopicsAPI("GET", url);
    }, [nativeLanguage, language]);

    useEffect(() => {
        if (topicsAPI.response) {
            setTopicsByCategory(topicsAPI.response.categories);
        }
    }, [topicsAPI.response]);

    return (
        <>
            <h1>
                {strings.topics} - {strings[language]}
            </h1>
            {!topicsAPI.loading && <p>{strings.topics_empty_state}</p>}

            {topicsByCategory.map((category) => (
                <>
                    <h2>{strings["topic_" + category.category]}</h2>
                    <ul>
                        {category.topics.map((topic) => (
                            <li>
                                <Link to={`/topics/${nativeLanguage}/${language}/${topic.topic}`}>
                                    {topic.topic_native}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </>
            ))}
        </>
    );
};

export default TopicsByCategoryScreen;
