import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from "chart.js";
import React, { useEffect } from "react";
import { Bar, Scatter } from "react-chartjs-2";
import { Segment } from "semantic-ui-react";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import { strings } from "../utils/i18n.utils";
import { StreakBar, TopStatistics } from "./TopStatistics";
import { ZataLoader } from "./ZataLoader";

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, Title, Tooltip, Legend);

export const COLOR1 = "#037ddb";
export const COLOR2 = "#f7b500";
export const COLOR3 = "#9a00fa";
export const COLOR_CORRECT = "#037ddb";
export const COLOR_INCORRECT = "#f7b500";

const ProgressChartStatistic = ({ statistic, statisticTitle }) => {
    return (
        <div style={{ flexGrow: 1 }}>
            <div
                style={{
                    flexGrow: 0,
                    marginRight: "1rem",
                    marginBottom: "0.2rem",
                    fontSize: "2rem",
                    fontWeight: 800,
                }}>
                {statistic}
            </div>
            <div>{statisticTitle}</div>
        </div>
    );
};
export const ProgressChart = ({ dataLabel, labels, data, datasets, chartType = "bar" }) => {
    const options = {
        animation: false,
        plugins: {
            legend: {
                display: datasets ? true : false,
                position: "bottom",
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return Math.round(context.parsed.y, 0);
                    },
                },
            },
        },
        datasets: {
            bar: {
                borderRadius: 20,
            },
        },
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                stacked: true,
            },
            y: {
                grid: {
                    display: false,
                },
                stacked: true,
            },
        },
    };

    const chartData = {
        labels,
        datasets: datasets
            ? datasets
            : [
                  {
                      label: dataLabel,
                      data: data,
                      backgroundColor: COLOR1,
                  },
              ],
    };

    return (
        <Segment>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "0.5rem",
                }}>
                {chartData.datasets.map(
                    (d, i) =>
                        (d.showStatistic === undefined || d.showStatistic === true) && (
                            <ProgressChartStatistic
                                key={i}
                                statistic={d.statistic || d.data[d.data.length - 1]}
                                statisticTitle={d.statisticTitle || d.label}
                            />
                        )
                )}
            </div>
            <div style={{ height: datasets ? "9rem" : "7rem", marginTop: "1rem" }}>
                {chartType === "bar" && <Bar options={options} data={chartData} />}
                {chartType === "scatter" && <Scatter options={options} data={chartData} />}
            </div>
        </Segment>
    );
};

const Progress = () => {
    const [progressAPI, callProgressAPI] = useAPI({ loading: true });
    const { statistics, currentSpace } = useAuth();

    useEffect(() => {
        callProgressAPI("GET", `/api/spaces/${currentSpace.id}/progress`);
    }, []);

    if (progressAPI.loading) {
        return <ZataLoader />;
    }

    const labels = progressAPI.response?.map((p) => {
        const date = new Date(p.day.replace(/-/g, "/"));
        // is the date today?
        if (date.toDateString() === new Date().toDateString()) {
            return strings.today;
        }
        return "";
        // create day-month in the local language
        // return date.toLocaleString(nativeLanguage, { day: "2-digit", month: "short" });
    });

    return (
        <>
            <TopStatistics statistics={statistics} showAllLevels={true} />
            <StreakBar progress={progressAPI.response} />

            <ProgressChart
                dataLabel={strings.points_today}
                labels={labels}
                data={progressAPI.response.map((p) => p.points)}
            />

            <ProgressChart
                dataLabel={strings.activity_minutes_title}
                emoji={"🏋️‍♀️"}
                labels={labels}
                data={progressAPI.response.map((p) => Math.round(Math.max(p.activity_minutes, 0)))}
            />

            <ProgressChart
                labels={labels}
                emoji={"🗣️"}
                data={progressAPI.response.map((p) => Math.max(p.words_sent, 0))}
                dataLabel={strings.words_sent_title}
            />
            <ProgressChart
                dataLabel={strings.skill_components_needing_review}
                labels={labels}
                emoji={"🗣️"}
                data={progressAPI.response.map((p) => p.skill_components_needing_review)}
            />

            <ProgressChart
                dataLabel={strings.skill_vocab_pairs_known}
                labels={labels}
                emoji={"🗣️"}
                data={progressAPI.response.map((p) => p.skill_vocab_pairs_known)}
                datasets={[
                    {
                        label: strings.skill_vocab_pairs_studied,
                        data: progressAPI.response.map((p) => p.skill_vocab_pairs_studied),
                        backgroundColor: COLOR1,
                    },
                    {
                        label: strings.skill_vocab_pairs_not_yet_studied,
                        data: progressAPI.response.map((p) => p.skill_vocab_pairs_not_studied),
                        backgroundColor: COLOR3,
                    },
                ]}
            />

            <ProgressChart
                dataLabel={strings.flashcards_reviewed}
                labels={labels}
                emoji={"🗣️"}
                datasets={[
                    {
                        label: strings.flashcards_correct,
                        data: progressAPI.response.map((p) => p.flashcards_correct),
                        backgroundColor: COLOR1,
                    },
                    {
                        label: strings.flashcards_incorrect,
                        data: progressAPI.response.map((p) => p.flashcards_reviewed - p.flashcards_correct),
                        backgroundColor: COLOR2,
                    },
                ]}
            />
        </>
    );
};

export default Progress;
