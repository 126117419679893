import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Topic } from "../components/Topic";
import { ZataLoader } from "../components/ZataLoader";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import useTitle from "../hooks/useTitle";
import { strings } from "../utils/i18n.utils";
import { Row } from "../components/Flex";
import { Button } from "semantic-ui-react";
import { Plus } from "@phosphor-icons/react";
import { currentLocation } from "../utils/url.utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const SpaceTopicScreen = ({}) => {
    const { topicName } = useParams();
    const { currentSpace } = useAuth();
    const [topicAPI, callTopicAPI] = useAPI();
    const history = useHistory();
    const { setPageTitle, setNavigationTitle, setDescription } = useTitle();

    const reloadTopic = () => {
        callTopicAPI(
            "GET",
            `/api/topics/${currentSpace.language}/${topicName}?native_language=${currentSpace.native_language}&include_help=true`
        );
    };

    useEffect(() => {
        reloadTopic();
    }, [topicName]);

    useEffect(() => {
        if (topicAPI.response) {
            setPageTitle(topicAPI.response.topic_native || topicAPI.response.topic);
        }
    }, [topicAPI.response]);

    if (topicAPI.response === null || topicAPI.loading) {
        return <ZataLoader />;
    }

    return (
        <div>
            <h1>{topicAPI.response.topic_native}</h1>
            <p style={{ fontSize: "0.8rem" }}>{topicAPI.response.topic}</p>
            <Topic topic={topicAPI.response} reloadTopic={reloadTopic} nativeLanguage={currentSpace.native_language} />;
        </div>
    );
};

export default SpaceTopicScreen;
