import { BellZ, Check, ThumbsDown, Trash, X } from "@phosphor-icons/react";
import { Button, Modal, Popup } from "semantic-ui-react";
import { Column } from "./Flex";
import { strings } from "../utils/i18n.utils";

const FlashcardStop = ({ flashcard, skillComponentReview, sendCommand, close }) => {
    function sendCommandInternal(command) {
        sendCommand(command);
        close();
    }

    return (
        <div>
            <h1>
                {skillComponentReview.skill_component.skill.name_native ||
                    skillComponentReview.skill_component.skill.name}
                : {skillComponentReview.skill_component.vocab_phrase?.text}
            </h1>
            <Column>
                <Button
                    fluid
                    icon={<BellZ />}
                    onClick={() => sendCommandInternal("next")}
                    content={strings.snooze_one_day}
                />
                <Button
                    fluid
                    icon={<Check />}
                    onClick={() => sendCommandInternal("mark_skill_known")}
                    content={strings.exercise_stop_known}
                />
                <Button
                    fluid
                    icon={<ThumbsDown />}
                    onClick={() => sendCommandInternal("archive")}
                    content={strings.exercise_stop_error}
                />
                <Button
                    fluid
                    icon={<Trash />}
                    onClick={() => sendCommandInternal("remove_skill")}
                    content={strings.exercise_stop_remove}
                />
                <Button fluid icon={<X />} onClick={() => close()} content={strings.cancel} />
            </Column>
        </div>
    );
};

export const FlashcardStopPopup = ({ flashcard, skillComponentReview, sendCommand, isOpen, setIsOpen }) => {
    return (
        <Modal
            open={isOpen}
            onClose={() => setIsOpen(false)}
            content={
                <FlashcardStop
                    flashcard={flashcard}
                    skillComponentReview={skillComponentReview}
                    sendCommand={sendCommand}
                    close={() => setIsOpen(false)}
                />
            }
            closeIcon={true}
        />
    );
};

export default FlashcardStopPopup;
